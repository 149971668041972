<template>
    <div style="width:180px" class="grid grid-cols-6 gap-4">
      <vs-button v-if="$acl.check('stockEdit')" xl class="col-start-1 col-end-7" color="primary" @click="restock">
        <template v-if="data.type !== 'materiel'">
          {{ $t('stock.list.table.actions.restock') }}
        </template>
        <template v-else>
        {{ $t(`stock.list.table.actions.${data.stock > 0 ? 'destock': 'restock'}`) }}
        </template>
      </vs-button>
      <feather-icon v-if="$acl.check('stockEdit')" class="col-start-1 col-end-3 justify-center" icon="Edit3Icon" svgClasses="hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon class="col-start-3 col-end-5 justify-center" icon="EyeIcon" svgClasses="hover:text-primary cursor-pointer" @click="seeStockEvents" />
      <feather-icon v-if="$acl.check('stockEdit')" class="col-start-5 col-end-7 justify-center" icon="Trash2Icon" svgClasses="hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'StockListActions',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    editRecord () {
      this.$router.push({'name': 'stock-edit', 'params': {id: this.data.id }})
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('stock.list.table.actions.delete.dialog.title', { title: this.data.title }),
        text: this.$t('stock.list.table.actions.delete.dialog.text', { title: this.data.title }),
        accept: this.deleteRecord,
        acceptText: this.$t('stock.list.table.actions.delete.dialog.acceptText'),
        cancelText: this.$t('stock.list.table.actions.delete.dialog.cancelText')
      })
    },
    deleteRecord () {
      this.$store.dispatch('stock/deleteStock', this.data.id)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       }) // eslint-disable-line no-console
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: this.$t('stock.list.table.actions.delete.notify.title', { title: this.data.title }),
        text: this.$t('stock.list.table.actions.delete.notify.text', { title: this.data.title })
      })
    },
    restock () {
      this.$router.push({'name': 'stock-restock', 'params': {id: this.data.id }})
    },
    seeStockEvents () {
      this.$emit('seeStockEvents', this.data.stockEvents)
    }
  }
}
</script>
