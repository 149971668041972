<template>
  <div class="vx-card p-6">
    <vs-table ref="" class="w-100 my-4" stripe sst hoverFlat :data="data">
        <template slot-scope="{data}">
        <vs-th sort-key="createdAt">
          {{ $t('stock.list.table.events.header.date') }}
        </vs-th>
        <vs-th>
          {{ $t('stock.list.table.events.header.variation') }}
        </vs-th>
        <vs-th>
          {{ $t('stock.list.table.events.header.raison') }}
        </vs-th>
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td class="max-w-xs" :data="data[indextr].createdAt">
            {{ getDateStockEvent(data[indextr].createdAt) }}
          </vs-td>
          <vs-td class="max-w-xs" :data="data[indextr].variation">
            <span :class="data[indextr].variation < 1 ? 'text-danger' : 'text-success'">
              {{ getVariationValue(data[indextr].variation)}}
            </span>
          </vs-td>
          <vs-td :data="data[indextr].raison">
            {{ data[indextr].raison }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>


export default {
  data () {
    return {

    }
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    getDateStockEvent (date) {
      const parseDate = new Date(Date.parse(date))
      return parseDate.toUTCString()
    },
    getVariationValue (variation) {
      if (this.$route.name === 'stock-list-materiel') {
        return variation < 0 ? this.$t('stock.list.table.materiel.stock.restock') : this.$t('stock.list.table.materiel.stock.destock')
      } else {
        return variation
      }
    }
  }
}
</script>

<style scoped>

</style>