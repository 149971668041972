<template>
  <div class="vx-card p-6">
    <vs-table :class="isFetching ? 'table-data-loading' : ''" ref="" class="w-100 my-4" stripe sst :data="stockData" @sort="onTableSort">
      <template slot="header">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow my-3">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ $t('stock.list.number.show') }}</span>
              <span class="mr-2"> {{ paginationPageSize }}</span>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="pageSize in pageSizeList" @click="paginationPageSize=pageSize" :key="pageSize">
                  <span>{{ pageSize }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </div>
          </vs-dropdown>
        </div>
        <vs-button v-if="showButton()" class="mr-8" type="relief" icon="post_add" @click="addNewStock">{{ $t(`stock.list.link.create_${filterItem.type}`) }}</vs-button>
        <vs-select v-if="!isMateriel" @input="filterType" :placeholder="$t('stock.form.input.type')"
          v-model="filterItem.type"
          class="mr-5"
        >
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in optionsType" />
        </vs-select>

        <!-- TABLE ACTION COL-2: SEARCH -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" :placeholder="$t('stock.list.search.placeholder')" />
      </template>
      <template slot="thead">
      </template>
      <template slot-scope="{data}">
        <vs-th>
          {{ $t('stock.list.table.header.image') }}
        </vs-th>
        <vs-th sort-key="title">
          {{ $t('stock.list.table.header.title') }}
        </vs-th>
        <vs-th sort-key="description">
          {{ $t('stock.list.table.header.description') }}
        </vs-th>
        <vs-th v-if="!isMateriel" sort-key="companies">
          {{ $t('stock.list.table.header.company') }}
        </vs-th>
        <vs-th v-if="!isMateriel" sort-key="unitPrice">
          {{ $t('stock.list.table.header.price') }}
        </vs-th>
        <vs-th sort-key="stock">
          {{ $t('stock.list.table.header.stock') }}
        </vs-th>
        <vs-th sort-key="location">
          {{ $t('stock.list.table.header.location') }}
        </vs-th>
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].imageStock">
            <stock-image v-if="data[indextr].imageStock" :data="data[indextr].imageStock" />
          </vs-td>
          <vs-td class="max-w-xs" :data="data[indextr].title">
            {{ data[indextr].title }}
          </vs-td>
          <vs-td class="max-w-xs" :data="data[indextr].description">
            {{ data[indextr].description }}
          </vs-td>
          <vs-td v-if="!isMateriel" :data="data[indextr].companies">
            <div v-for="company in data[indextr].companies" :key="company">
              {{ company }}
            </div>
          </vs-td>
          <vs-td v-if="!isMateriel" :data="data[indextr].unitPrice">
            {{ data[indextr].unitPrice }}
          </vs-td>
          <vs-td :data="data[indextr].stock">
            <template v-if="!isMateriel">
              {{ data[indextr].stock }}
            </template>
            <template v-else>
              <span v-if="data[indextr].stock < 1" class="text-danger" >
                {{ $t('stock.list.table.materiel.stock.restock') }}
              </span>
              <span v-else class="text-success" >
                {{ $t('stock.list.table.materiel.stock.destock') }}
              </span>
            </template>
          </vs-td>
          <vs-td :data="data[indextr].storageArea">
            {{ data[indextr].storageArea }}
          </vs-td>
          <vs-td>
            <stock-list-actions @seeStockEvents="seeStockEvents" :data="data[indextr]" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage" />
    <vs-popup :title="`${$t('stock.list.popup.events.title')}`"  :active.sync="popupActivo">
      <table-stock-events :data="stockEvents" />
    </vs-popup>
  </div>
</template>

<script>
//store
import moduleStockMixin from '@/store/stock/moduleStockMixin'

// CellRenderer
import StockListActions from './cell-renderer/CellRendererActions'
import StockImage from './cell-renderer/CellRendererStockImage'

import tableStockEvents from '@/views/apps/stock/stock-list/TableStockEvents'

export default {
  components: {StockListActions, StockImage, tableStockEvents},
  mixins: [moduleStockMixin],
  data () {
    return {
      currentPage: 1,
      maxPageNumbers: 7,
      paginationPageSize: 10,
      oldPaginationPageSize: 10,
      pageSizeList: [10, 20, 30],
      activePromptNewStock: false,
      searchItem: {},
      sortItem: {},
      searchQuery: '',
      filterItem: {},
      stockEvents: [],
      popupActivo: false,
      isFetching: false,
      optionsType: [
        ...this.$acl.check('stockEnovos') ? [{ text:'Brochure & Gadget', value:['brochure', 'gadget'] }] : [],
        {
          text:'Gadget',
          value:'gadget'
        },
        ...this.$acl.check('stockEnovos') ? [{ text:'Brochure', value:'brochure' }] : []
      ]
    }
  },
  computed: {
    stockData () {
      return this.$store.state.stock.stock
    },
    totalItems () {
      return this.$store.state.stock.totalItems
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.paginationPageSize)
    },
    rowEnd () {
      if (this.stockData.length - (this.currentPage * this.paginationPageSize) > 0) {
        return this.currentPage * this.paginationPageSize
      }

      return this.stockData.length
    },
    routerParam () {
      return this.$route.params.type 
    },
    isMateriel () {
      return this.$route.name === 'stock-list-materiel'
    }
  },
  watch: {
    'filterItem' () {
      this.fetchStock()
      this.currentPage = 1
    },
    'currentPage' () {
      this.fetchStock()
    },
    'paginationPageSize' () {
      if (this.rowStart > this.totalItems) {
        this.currentPage = Math.ceil(this.totalItems / this.paginationPageSize)
        return
      }
      this.fetchStock()
    },
    '$route.params.type' () {
      this.$delete(this.filterItem, 'type')
      this.filterItem.type = this.routerParam
    }
  },
  mounted () {
    this.filterItem.type = this.routerParam ? this.routerParam : 'materiel'
    this.fetchStock()
  },
  methods: {
    fetchStock () {
      const payload = {
        ...(!this.searchItem.title && { page: this.currentPage }),
        itemsPerPage: this.paginationPageSize,
        ...this.sortItem,
        ...this.searchItem,
        ...(this.filterItem.type && {...this.filterItem}),
        ...(this.$route.name === 'stock-list-materiel' && { type: 'materiel' })
      }
      this.isFetching = true
      this.$store.dispatch('stock/fetchStocks', payload)
        .then(() => {
          this.isFetching = false
          return this.isFetching 
        })
      this.oldPaginationPageSize = this.paginationPageSize
    },
    onTableSort (field, order) {
      this.sortItem = {}
      if (order) {
        this.sortItem = {
          order: {
            [field]: order
          }
        }
      }
      this.fetchStock()
    },
    updateSearchQuery () {
      if (this.searchQuery === '') {
        this.searchItem = {}
        this.fetchStock()
        return
      }
      this.searchItem = {
        'title[ior]': this.searchQuery,
        'description[ior]': this.searchQuery,
        'storageArea[ior]': this.searchQuery,
        'companies[ior]': this.searchQuery
      }
      this.fetchStock()
    },
    filterType () {
      this.filterItem = {
        ...this.filterItem
      }
    },
    addNewStock () {
      if (Array.isArray(this.filterItem.type)) {
        this.$router.push({name: 'stock-add', params: {type: 'brochure'} })
      } else {
        this.$router.push({name: 'stock-add', params: {type: this.filterItem.type} })
      }
    },
    seeStockEvents (stockEvents) {
      this.stockEvents = stockEvents
      this.popupActivo = true
    },
    showButton () {
      if (this.$acl.check('stockEdit')) {
        if (this.$route.name === 'stock-list-materiel') {
          if (!this.$acl.check('stockEncevo') || this.$acl.check('stockAdmin') || this.$acl.check('stockEnovos')) {
            return true
          } else {
            return false
          }
        }
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-data-loading {
    td {
      opacity: 0.2;
    }
  }
</style>
